<template>
  <div class="page-layout">
    <NavBar></NavBar>
    <div class="page-content">
      <div class="welcome">
        你好，<br />
        欢迎登录振合网实体联盟
      </div>
      <van-form>
        <van-cell-group :border="false">
          <van-field v-model="mobile" placeholder="输入手机号" type="number">
            <template #label>
              <div class="label-title">
                <span>手机号</span>
                <!--<van-icon name="arrow-down" />-->
              </div>
            </template>
          </van-field>
        </van-cell-group>
        <van-field
          v-model="sms"
          center
          clearable
          label="短信验证码"
          placeholder="输入验证码"
        >
          <template #button>
            <div v-if="showBtn" class="time" @click="onTimer">获取验证码</div>
            <div v-else class="time">{{ timeNum }}秒</div>
          </template>
        </van-field>
        <div class="button-box">
          <van-button
            color="linear-gradient(to right, #F9A075, #FF3E46)"
            round
            block
            @click="onSubmit"
          >
            立即登录
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { getSignInInfo } from "@/api/index.js";
import { commonSend } from "@/api/common.js";
import { Toast } from "vant";
import NavBar from "@/layout/nav-bar/nav-bar";
export default {
  name: "login-page",
  components: {
    NavBar,
  },
  data() {
    return {
      timeNum: 0,
      showBtn: true,
      mobile: "",
      sms: "",
    };
  },
  methods: {
    async onTimer() {
      const that = this;
      this.showBtn = false;
      this.timeNum = 60;
      const timer = setInterval(() => {
        that.timeNum--;
        if (that.timeNum === 0) {
          clearTimeout(timer);
          that.showBtn = true;
        }
      }, 1000);
      await commonSend({
        phone: this.mobile,
        send_type: "register",
      });
    },
    async onSubmit() {
      if (this.mobile === "") {
        Toast({
          message: "请输入手机号码",
          position: "top",
        });
        return false;
      }
      if (this.sms === "") {
        Toast({
          message: "请输入验证码",
          position: "top",
        });
        return false;
      }
      if (this.sms !== "" && this.mobile !== "") {
        let invite_code = localStorage.getItem("INVITE_CODE");
        const res = await getSignInInfo({
          phone: this.mobile,
          code: this.sms,
          invite_code: invite_code,
        });
        this.$store.commit("setCookie", res.data);
        if (res.code * 1 === 1) {
          this.$router.push("/");
        } else {
          Toast({
            message: res.msg,
            position: "top",
          });
        }
      } else {
        Toast({
          message: "请输入必填项",
          position: "top",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-layout {
  min-height: 100vh;
  background-color: #fff;
}
.page-content {
  padding: 138px 26px 0 26px;
  .welcome {
    font-size: 20px;
    font-weight: 600;
  }
}
.button-box {
  color: #fff;
  padding: 26px;
}
//vant组件样式
.van-form {
  padding-top: 12px;
}
.van-cell {
  padding: 14px 0;
  border-bottom: 1px solid rgb(240, 240, 240);
}
/deep/.van-cell__title span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.label-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  span {
    padding-right: 6px;
  }
}
.time {
  line-height: 24px;
  color: #000;
  font-size: 16px;
}
.van-button--default {
  border: none;
}
</style>
